import React from "react"
import {Link} from "gatsby"
import Container from "../components/Container/Container";
import styles from "../templates/blogPage/Blog.module.scss";
import PageHeader from "../components/PageHeader/PageHeader";
import {STATIC_TEXTS} from "../resources/staticTexts";

const example = () => {
  const renderSimilarPosts = () => <div className={styles.similarPostsWrapper}>
    <div>
      <p className={styles.postHeader}>
        {STATIC_TEXTS.similarPosts}
      </p>
      <ul>
        {[...Array(5).keys()].map(index => <Link to={`/index`}>
          <li>
            Podobny post {index}
          </li>
        </Link>)}
      </ul>
    </div>
    <div className={styles.tags}>
      <p className={styles.postHeader}>{STATIC_TEXTS.tags}</p>
      <div>
        {[...Array(5).keys()].map(tag => <Link to={`/index`}>tag {tag}</Link>)}
      </div>
    </div>
  </div>;

  return <Container className={styles.container}>
    <PageHeader header={"Przykład wpisu"}/>
    <div className={styles.contentWrapper}>
      <div className={styles.article}>
        <p className={styles.postHeader}>
          {`28/03/2021, Jakub`}
        </p>
        <div className={styles.postContent}>
          <h1>To jest Heading 1</h1>
          <h2>To jest Heading 2</h2>
          <h3>To jest Heading 3 - nagłówek z kreską powyżej - np. między sekcjami artykułu</h3>
          <h4>To jest Heading 4 - np. tekst, który wymaga zapamiętania. Jest wyśrodkowany.</h4>
          <h5>To jest Heading 5 - mniejszy nagłówek, np. do podtytułu</h5>
          <h6>To jest Heading 6 - na przykład jakieś piśmiennictwo</h6>
          <p>To jest Normal Text - treść artykułu</p>
        </div>
      </div>
      {renderSimilarPosts()}
    </div>
  </Container>;
}

export default example
